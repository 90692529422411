import { NumCSV, StrVal, withDefault, ParsedParams, routesConfigHelper, Enum } from "@libs/router/url";
import {
  ClaimInsuranceOrdinal,
  ClaimInsurancePaymentStatus,
  ClaimState,
  FilterStatus,
} from "components/Claims/types";

export type SortDirection = "ASCENDING" | "DESCENDING";
export type SortField =
  | "SERVICE_DATE"
  | "PATIENT_LAST_NAME"
  | "TREATING_PROVIDER_LAST_NAME"
  | "SUBMITTED_AT"
  | "NEEDS_ATTACHMENT";

const DEFAULT_SORT_FIELD: SortField = "SERVICE_DATE";
const DEFAULT_SORT_DIRECTION: SortDirection = "DESCENDING";

export const ClaimsQueryParams = {
  billingProviderIds: NumCSV,
  insuranceCarrierIds: NumCSV,
  insuranceOrdinals: Enum<ClaimInsuranceOrdinal>().CSV(["PRIMARY", "SECONDARY", "OTHER"]),
  insurancePaymentStatuses: Enum<ClaimInsurancePaymentStatus>().CSV([
    "UNPAID",
    "NOT_SUBMITTED",
    "PAID_AS_EXPECTED",
    "OVERPAID",
    "UNDERPAID",
    "DENIED",
  ]),
  search: StrVal,
  sortField: withDefault(
    Enum<SortField>().Val([
      "SERVICE_DATE",
      "PATIENT_LAST_NAME",
      "TREATING_PROVIDER_LAST_NAME",
      "SUBMITTED_AT",
      "NEEDS_ATTACHMENT",
    ]),
    DEFAULT_SORT_FIELD
  ),
  sortDirection: withDefault(Enum<SortDirection>().Val(["ASCENDING", "DESCENDING"]), DEFAULT_SORT_DIRECTION),
  states: Enum<ClaimState>().CSV(["PENDING", "SUBMITTED", "FAILED", "COMPLETED", "SUBMITTING", "VOIDED"]),
  statuses: Enum<FilterStatus>().CSV([
    FilterStatus.COMPLETED,
    FilterStatus.NOT_SUBMITTED,
    FilterStatus.SUBMITTED,
    FilterStatus.VOIDED,
  ]),
  treatingProviderIds: NumCSV,
};

const ClaimPathParams = { claimUuid: StrVal };
const ClaimQueryParams = { from: StrVal };
const EobPathParams = { draftEobPaymentUuid: StrVal };

export const ClaimRoutesConfig = routesConfigHelper({
  preAuths: { path: "/claims/pre-auths", query: ClaimsQueryParams },
  claims: { path: "/claims", query: ClaimsQueryParams },
  eobs: { path: "/claims/eobs", query: ClaimsQueryParams },
  claim: {
    path: "/claims/:claimUuid",
    params: ClaimPathParams,
    query: ClaimQueryParams,
  },
  claimAdjustment: {
    path: "/claims/:claimUuid/add-adjustment",
    params: ClaimPathParams,
    query: ClaimQueryParams,
  },
  claimAttachments: {
    path: "/claims/:claimUuid/attachments",
    params: ClaimPathParams,
    query: ClaimQueryParams,
  },
  claimEobs: {
    path: "/claims/:claimUuid/eobs",
    params: ClaimPathParams,
    query: ClaimQueryParams,
  },
  claimHistory: {
    path: "/claims/:claimUuid/history",
    params: ClaimPathParams,
    query: ClaimQueryParams,
  },
  eob: {
    path: "/claims/eobs/:draftEobPaymentUuid",
    params: EobPathParams,
    query: ClaimQueryParams,
  },
});
export type ClaimsQuery = ParsedParams<typeof ClaimsQueryParams>;
export type ClaimsQueryUpdates = Partial<ClaimsQuery>;
