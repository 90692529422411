import { routesConfigHelper } from "@libs/router/url";

export const DEFAULT_DATASOURCE = "323a128e-2df4-4c5a-8b05-7e05653df57b";

export const DashboardV2RoutesConfig = routesConfigHelper({
  dashboardV2AskAI: {
    path: "/dashboard-v2/ask-ai",
  },
  dashboardV2OverallSummary: {
    path: "/dashboard-v2/overall-summary",
  },
  dashboardV2Scorecard: {
    path: "/dashboard-v2/scorecard",
  },
});
