import { useState } from "react";
import { PracticeVO } from "@libs/api/generated-api";
import { getEmptyAddress } from "@libs/utils/address";

type PracticeDraft = PracticeVO & {
  contactDetails: NonNullable<PracticeVO["contactDetails"]>;
  physicalAddress: NonNullable<PracticeVO["physicalAddress"]>;
};

export const resetDraft = (practice: PracticeVO): PracticeDraft => {
  return {
    ...practice,
    contactDetails: practice.contactDetails ?? {},
    physicalAddress: practice.physicalAddress ?? getEmptyAddress(),
  };
};

export const usePracticeDraft = (practice: PracticeVO) => {
  return useState(() => resetDraft(practice));
};
