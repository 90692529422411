import { redirectLoader } from "@libs/router/redirectLoader";
import { isOneOf } from "@libs/utils/isOneOf";
import { SentryRouteErrorFallback } from "components/Main/SentryRouteErrorFallback";
import { loader as signOutLoader } from "components/Main/SignOutRoute.loader";
import { loader as mfaSetupLoader } from "components/MfaSetup/MfaSetupRoute.loader";
import { loader as selectAccountLoader } from "components/Main/SelectAccountRoute.loader";
import { loader as signedInAppLoader } from "components/Main/SignedInApp.loader";
import { authLoader } from "components/SignIn/authLoader";
import { paths, routesConfig } from "utils/routing/paths";
import { PracticeRouterContext } from "router/types";
import { patientRoutePermissions } from "components/PatientProfile/routes";
import { mapRoutes } from "router/mapRoutes";

export const getRoutes = (context: PracticeRouterContext) => {
  return mapRoutes([
    {
      errorElement: <SentryRouteErrorFallback />,
      loadComponent: () => import("components/Main/AppRoot").then((mod) => mod.AppRoot),
      children: [
        {
          path: routesConfig.signOut.path,
          loader: signOutLoader(context),
          loadComponent: () => import("components/Main/SignOutRoute").then((mod) => mod.SignOutRoute),
        },
        {
          path: routesConfig.postSignOut.path,
          loadComponent: () => import("components/Main/PostSignOutRoute").then((mod) => mod.PostSignOutRoute),
        },
        {
          path: routesConfig.selectAccount.path,
          loader: selectAccountLoader(context),
          loadComponent: () =>
            import("components/Main/SelectAccountRoute").then((mod) => mod.SelectAccountRoute),
        },
        {
          path: routesConfig.mfaSetup.path,
          loader: mfaSetupLoader(context),
          loadComponent: () => import("components/MfaSetup/MfaSetupRoute").then((mod) => mod.MfaSetupRoute),
        },
        {
          path: routesConfig.signIn.path,
          loader: authLoader(context, "signIn"),
          loadComponent: () => import("components/SignIn/MainSignIn").then((mod) => mod.MainSignIn),
        },
        {
          // Deprecated. User auth/sign-in?resetPassword=1 instead
          path: routesConfig.firstSignIn.path,
          loader: redirectLoader(paths.signIn({ resetPassword: true })),
        },
        {
          path: routesConfig.forgotPassword.path,
          loader: authLoader(context, "forgotPassword"),
          loadComponent: () => import("components/SignIn/ForgotPassword").then((mod) => mod.ForgotPassword),
        },
        {
          path: routesConfig.supportSignIn.path,
          loader: authLoader(context, "supportSignIn"),
          loadComponent: () => import("components/SignIn/SupportSignIn").then((mod) => mod.SupportSignIn),
        },
        {
          path: routesConfig.oauthSignIn.path,
          loadComponent: () => import("components/SignIn/OAuthSignIn").then((mod) => mod.OAuthSignIn),
        },

        // Legacy paths
        {
          path: "sign-in",
          loader: redirectLoader(paths.signIn()),
        },
        {
          path: "first-sign-in",
          loader: redirectLoader(paths.firstSignIn()),
        },
        {
          path: "forgot-password",
          loader: redirectLoader(paths.forgotPassword()),
        },
        {
          loader: signedInAppLoader(context),
          loadComponent: () => import("components/Main/SignedInApp").then((mod) => mod.SignedInApp),
          children: [
            {
              loadComponent: () =>
                import("components/Onboarding/OnboardingGuard").then((mod) => mod.OnboardingGuard),
              children: [
                {
                  loadComponent: () =>
                    import("components/Onboarding/EmployeeOnboardingGuard").then(
                      (mod) => mod.EmployeeOnboardingGuard
                    ),
                  children: [
                    {
                      path: paths.onboarding({ step: "personal-info" }),
                      loadComponent: () =>
                        import("components/Onboarding/OnboardingPersonalInfoRoute").then(
                          (mod) => mod.OnboardingPersonalInfoRoute
                        ),
                    },
                    {
                      path: paths.onboarding({ step: "licensing-info" }),
                      loadComponent: () =>
                        import("components/Onboarding/OnboardingLicensingInfoRoute").then(
                          (mod) => mod.OnboardingLicensingInfoRoute
                        ),
                    },
                  ],
                },
                {
                  loadComponent: () =>
                    import("components/Onboarding/PracticeOnboardingGuard").then(
                      (mod) => mod.PracticeOnboardingGuard
                    ),
                  children: [
                    {
                      path: paths.onboarding({ step: "practice-owner" }),
                      loadComponent: () =>
                        import(
                          "components/Onboarding/OnboardingPracticeOwner/OnboardingPracticeOwnerRoute"
                        ).then((mod) => mod.OnboardingPracticeOwnerRoute),
                    },
                    {
                      path: paths.onboarding({ step: "practice-info" }),
                      loadComponent: () =>
                        import("components/Onboarding/OnboardingPracticeInfoRoute").then(
                          (mod) => mod.OnboardingPracticeInfoRoute
                        ),
                    },
                    {
                      path: paths.onboarding({ step: "business-info" }),
                      loadComponent: () =>
                        import("components/Onboarding/OnboardingBusinessInfoRoute").then(
                          (mod) => mod.OnboardingBusinessInfoRoute
                        ),
                    },
                  ],
                },
              ],
            },
            {
              loadComponent: () => import("components/Main/OnboardedGuard").then((mod) => mod.OnboardedGuard),
              children: [
                {
                  loadComponent: () => import("components/Main/AuthLayout").then((mod) => mod.AuthLayout),
                  children: [
                    {
                      path: "/",
                      loadComponent: () => import("components/HomeRoute").then((mod) => mod.HomeRoute),
                    },
                    {
                      permission: {
                        domain: "REPORTING",
                        action: "ACCESS_ALL",
                      },
                      loadComponent: () =>
                        import("components/Dashboard/DashboardRoute").then((mod) => mod.DashboardRoute),

                      children: [
                        {
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_FINANCIAL",
                          },
                          loadComponent: () =>
                            import("components/Dashboard/PracticeProduction/PracticeProductionRoute").then(
                              (mod) => mod.PracticeProductionRoute
                            ),

                          children: [
                            {
                              path: paths.dashboardPracticeProduction({ tableTab: "providers" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/PracticeProduction/ProductionByProviderTableRoute"
                                ).then((mod) => mod.ProductionByProviderTable),
                            },
                            {
                              path: paths.dashboardPracticeProduction({ tableTab: "hygienists" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/PracticeProduction/ProductionByHygienistTableRoute"
                                ).then((mod) => mod.ProductionByHygienistTableRoute),
                            },
                            {
                              path: paths.dashboardPracticeProduction({ tableTab: "appointments" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/PracticeProduction/ProductionByAppointmentTableRoute"
                                ).then((mod) => mod.ProductionByAppointmentTableRoute),
                            },
                            {
                              path: paths.dashboardPracticeProduction({ tableTab: "procedures" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/PracticeProduction/ProductionByProcedureTableRoute"
                                ).then((mod) => mod.ProductionByProcedureTableRoute),
                            },
                          ],
                        },
                        {
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_FINANCIAL",
                          },
                          loadComponent: () =>
                            import(
                              "components/Dashboard/OutstandingCollections/OutstandingCollectionsRoute"
                            ).then((mod) => mod.OutstandingCollectionsRoute),

                          children: [
                            {
                              path: paths.dashboardPaymentOutstanding({ tableTab: "carriers" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/OutstandingCollections/ByCarrierTableRoute"
                                ).then((mod) => mod.ByCarrierTableRoute),
                            },
                            {
                              path: paths.dashboardPaymentOutstanding({ tableTab: "service" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/OutstandingCollections/ByServiceTableRoute"
                                ).then((mod) => mod.ByServiceTableRoute),
                            },
                            {
                              path: paths.dashboardPaymentOutstanding({ tableTab: "patients" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/OutstandingCollections/ByPatientAgingTableRoute"
                                ).then((mod) => mod.ByPatientAgingTableRoute),
                            },
                          ],
                        },
                        {
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_FINANCIAL",
                          },
                          loadComponent: () =>
                            import("components/Dashboard/PaymentCollected/PaymentCollectedRoute").then(
                              (mod) => mod.PaymentCollectedRoute
                            ),

                          children: [
                            {
                              path: paths.dashboardPaymentCollected({ tableTab: "payment-methods" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/PaymentCollected/ByPaymentMethodTableRoute"
                                ).then((mod) => mod.ByPaymentMethodTableRoute),
                            },
                            {
                              path: paths.dashboardPaymentCollected({ tableTab: "patients" }),
                              loadComponent: () =>
                                import("components/Dashboard/PaymentCollected/ByPatientTableRoute").then(
                                  (mod) => mod.ByPatientTableRoute
                                ),
                            },
                            {
                              path: paths.dashboardPaymentCollected({ tableTab: "providers" }),
                              loadComponent: () =>
                                import("components/Dashboard/PaymentCollected/ByProviderTableRoute").then(
                                  (mod) => mod.ByProviderTableRoute
                                ),
                            },
                            {
                              path: paths.dashboardPaymentCollected({ tableTab: "associated-provider" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/PaymentCollected/ByAssociatedProviderTableRoute"
                                ).then((mod) => mod.ByAssociatedProviderTableRoute),
                            },
                          ],
                        },
                        {
                          path: routesConfig.dashboardPatientWallet.path,
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_FINANCIAL",
                          },
                          loadComponent: () =>
                            import("components/Dashboard/PatientWallet/PatientWalletRoute").then(
                              (mod) => mod.PatientWalletRoute
                            ),
                        },
                        {
                          path: routesConfig.dashboardPatientsTreated.path,
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_OPERATIONAL",
                          },
                          loadComponent: () =>
                            import("components/Dashboard/PatientsTreated/PatientsTreatedRoute").then(
                              (mod) => mod.PatientsTreatedRoute
                            ),
                        },
                        {
                          path: routesConfig.dashboardAppointmentsCompleted.path,
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_OPERATIONAL",
                          },
                          loadComponent: () =>
                            import(
                              "components/Dashboard/AppointmentsCompleted/AppointmentsCompletedRoute"
                            ).then((mod) => mod.AppointmentsCompletedRoute),
                        },
                        {
                          // `AppointmentDrilldownRoute` references reports that have varying permission
                          // requirements. The reports requested are driven by query params so the permissions
                          // can't be configured here. It's done within the component.
                          path: routesConfig.dashboardAppointmentDrilldown.path,
                          loadComponent: () =>
                            import(
                              "components/Dashboard/AppointmentDrilldown/AppointmentDrilldownRoute"
                            ).then((mod) => mod.AppointmentDrilldownRoute),
                        },
                        {
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_FINANCIAL",
                          },
                          loadComponent: () =>
                            import("components/Dashboard/Adjustments/AdjustmentsRoute").then(
                              (mod) => mod.AdjustmentsRoute
                            ),

                          children: [
                            {
                              path: paths.dashboardAdjustments({ tableTab: "insurance" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/Adjustments/InsuranceAdjustmentsTableRoute"
                                ).then((mod) => mod.InsuranceAdjustmentsTableRoute),
                            },
                            {
                              path: paths.dashboardAdjustments({ tableTab: "collection" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/Adjustments/CollectionAdjustmentsTableRoute"
                                ).then((mod) => mod.CollectionAdjustmentsTableRoute),
                            },
                            {
                              path: paths.dashboardAdjustments({ tableTab: "provider" }),
                              loadComponent: () =>
                                import("components/Dashboard/Adjustments/ProviderAdjustmentsTableRoute").then(
                                  (mod) => mod.ProviderAdjustmentsTableRoute
                                ),
                            },
                          ],
                        },
                        {
                          path: routesConfig.dashboardDailyReport.path,
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_FINANCIAL",
                          },
                          loadComponent: () =>
                            import("components/Dashboard/EndOfDayReport/EndOfDayReportRoute").then(
                              (mod) => mod.EndOfDayReportRoute
                            ),
                        },
                        {
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_HYGIENE",
                          },
                          loadComponent: () =>
                            import("components/Dashboard/RecallReport/RecallReportRoute").then(
                              (mod) => mod.RecallReportRoute
                            ),

                          children: [
                            {
                              path: paths.dashboardRecall({ tableTab: "patients" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/RecallReport/RecallReportByPatientTableRoute"
                                ).then((mod) => mod.RecallReportByPatientTableRoute),
                            },
                            {
                              path: paths.dashboardRecall({ tableTab: "procedures" }),
                              loadComponent: () =>
                                import(
                                  "components/Dashboard/RecallReport/RecallReportByProcedureTableRoute"
                                ).then((mod) => mod.RecallReportByProcedureTableRoute),
                            },
                          ],
                        },
                        {
                          path: routesConfig.dashboardUnscheduledTreatment.path,
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_OPERATIONAL",
                          },
                          loadComponent: () =>
                            import(
                              "components/Dashboard/UnscheduledTreatment/UnscheduledTreatmentRoute"
                            ).then((mod) => mod.UnscheduledTreatmentRoute),
                        },
                        {
                          path: routesConfig.dashboardReferrals.path,
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_OPERATIONAL",
                          },
                          loadComponent: () =>
                            import("components/Dashboard/Referrals/ReferralsRoute").then(
                              (mod) => mod.ReferralsRoute
                            ),
                        },
                        {
                          path: routesConfig.dashboardPayrollHours.path,
                          permission: {
                            domain: "REPORTING",
                            action: "ACCESS_ADMINISTRATIVE",
                          },
                          loadComponent: () =>
                            import("components/Dashboard/PayrollHours/PayrollHoursRoute").then(
                              (mod) => mod.PayrollHoursRoute
                            ),
                        },
                      ],
                    },
                    {
                      permission: {
                        domain: "REPORTING",
                        action: "ACCESS_ALL",
                      },
                      loadComponent: () =>
                        import("components/DashboardV2/DashboardV2Route").then((mod) => mod.DashboardV2Route),
                      children: [
                        {
                          loadComponent: () =>
                            import("components/DashboardV2/AskArchyIntelligenceRoute").then(
                              (mod) => mod.AskArchyIntelligenceRoute
                            ),
                          path: routesConfig.dashboardV2AskAI.path,
                        },
                        {
                          loadComponent: () =>
                            import("components/DashboardV2/OverallSummaryRoute").then(
                              (mod) => mod.OverallSummaryRoute
                            ),
                          path: routesConfig.dashboardV2OverallSummary.path,
                        },
                        {
                          loadComponent: () =>
                            import("components/DashboardV2/ScorecardRoute").then((mod) => mod.ScorecardRoute),
                          path: routesConfig.dashboardV2Scorecard.path,
                        },
                      ],
                    },
                    {
                      loadComponent: () =>
                        import("components/Patient/PatientsApp").then((mod) => mod.PatientsApp),

                      children: [
                        {
                          path: routesConfig.patients.path,
                          loadComponent: () =>
                            import("components/Patients/PatientsRoute").then((mod) => mod.PatientsRoute),
                        },
                        {
                          loadComponent: () =>
                            import("components/PatientProfile/PatientProfile").then(
                              (mod) => mod.PatientProfile
                            ),

                          children: [
                            {
                              path: routesConfig.patient.path,
                              permission: {
                                domain: patientRoutePermissions.overview.domain,
                                action: patientRoutePermissions.overview.action,
                              },
                              isLivePracticeRequired: true,
                              loadComponent: () =>
                                import("components/PatientProfile/PatientOverview").then(
                                  (mod) => mod.PatientOverview
                                ),
                            },
                            {
                              path: routesConfig.editPatient.path,
                              permission: {
                                domain: patientRoutePermissions.edit.domain,
                                action: patientRoutePermissions.edit.action,
                              },
                              loadComponent: () =>
                                import("components/PatientProfile/PatientOverview").then(
                                  (mod) => mod.PatientOverview
                                ),
                            },
                            {
                              permission: {
                                domain: patientRoutePermissions["medical-history"].domain,
                                action: patientRoutePermissions["medical-history"].action,
                              },
                              loadComponent: () =>
                                import("components/PatientProfile/MedicalHistory/MedicalHistoryApp").then(
                                  (mod) => mod.MedicalHistoryApp
                                ),

                              children: [
                                {
                                  path: routesConfig.medicalHistory.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/MedicalHistory/MedicalHistoryRoute"
                                    ).then((mod) => mod.MedicalHistoryRoute),
                                },
                              ],
                            },
                            {
                              permission: {
                                domain: patientRoutePermissions.insurance.domain,
                                action: patientRoutePermissions.insurance.action,
                              },
                              loadComponent: () =>
                                import("components/PatientProfile/Insurance").then(
                                  (mod) => mod.PatientInsurancePage
                                ),

                              children: [
                                {
                                  path: routesConfig.patientInsurance.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Insurance/InsurancePlans/PatientInsurancePlansRoute"
                                    ).then((mod) => mod.PatientInsurancePlansRoute),
                                },
                                {
                                  path: routesConfig.patientInsuranceDetails.path,
                                  loadComponent: () =>
                                    import("components/PatientProfile/Insurance/ForwardToInfoStep").then(
                                      (mod) => mod.ForwardToInfoStep
                                    ),
                                },
                                {
                                  path: routesConfig.patientInsuranceInfoStep.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/SubscriberRoute"
                                    ).then((mod) => mod.SubscriberRoute),
                                },
                                {
                                  path: routesConfig.patientInsuranceCarrierStep.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Insurance/InsuranceDetailsRoute/CarriersAndPlansTab/CarrierAndPlanRoute"
                                    ).then((mod) => mod.CarrierAndPlanRoute),
                                },
                                {
                                  path: routesConfig.patientInsuranceCoverageStep.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Insurance/InsuranceDetailsRoute/DedMaxAndCoverageTab/DeductiblesMaxCoverageRoute"
                                    ).then((mod) => mod.DeductiblesMaxCoverageRoute),
                                },
                                {
                                  path: routesConfig.patientInsuranceLimitationsStep.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/LimitationsRoute"
                                    ).then((mod) => mod.LimitationsRoute),
                                },
                              ],
                            },
                            {
                              permission: {
                                domain: patientRoutePermissions.claims.domain,
                                action: patientRoutePermissions.claims.action,
                              },
                              loadComponent: () =>
                                import("components/PatientProfile/Claims/PatientClaimsRoute").then(
                                  (mod) => mod.PatientClaimsRoute
                                ),

                              children: [
                                {
                                  path: routesConfig.patientClaims.path,
                                  loadComponent: () =>
                                    import("components/PatientProfile/Claims/PatientClaimsTabRoute").then(
                                      (mod) => mod.PatientClaimsTabRoute
                                    ),
                                },
                                {
                                  path: routesConfig.patientPreAuths.path,
                                  loadComponent: () =>
                                    import("components/PatientProfile/Claims/PatientPreAuthsTabRoute").then(
                                      (mod) => mod.PatientPreAuthsTabRoute
                                    ),
                                },
                              ],
                            },
                            {
                              path: routesConfig.patientLabCases.path,
                              permission: {
                                domain: patientRoutePermissions["lab-cases"].domain,
                                action: patientRoutePermissions["lab-cases"].action,
                              },
                              loadComponent: () =>
                                import("components/PatientProfile/LabCases/PatientLabCasesRoute").then(
                                  (mod) => mod.PatientLabCasesRoute
                                ),
                            },
                            {
                              permission: {
                                domain: patientRoutePermissions.charting.domain,
                                action: patientRoutePermissions.charting.action,
                              },
                              loadComponent: () =>
                                import("components/Charting/ChartingRoute").then((mod) => mod.ChartingRoute),

                              children: [
                                {
                                  path: routesConfig.charting.path,
                                  loadComponent: () =>
                                    import("components/Charting/ToothChartRoute").then(
                                      (mod) => mod.ToothChartRoute
                                    ),
                                },
                                {
                                  path: routesConfig.perio.path,
                                  loadComponent: () =>
                                    import("components/Charting/Perio/PerioChartRoute").then(
                                      (mod) => mod.PerioChartRoute
                                    ),
                                },
                                {
                                  path: routesConfig.perioCompare.path,
                                  loadComponent: () =>
                                    import("components/Charting/Perio/PerioCompare/PerioCompareRoute").then(
                                      (mod) => mod.PerioCompareRoute
                                    ),
                                },
                              ],
                            },
                            {
                              permission: {
                                domain: patientRoutePermissions.imaging.domain,
                                action: patientRoutePermissions.imaging.action,
                              },
                              loadComponent: () =>
                                import("components/PatientProfile/Imaging/PatientMountsList/route").then(
                                  (mod) => mod.PatientMountsListRoute
                                ),

                              children: [
                                {
                                  path: routesConfig.patientImaging.path,
                                  loadComponent: () =>
                                    import("components/PatientProfile/Imaging/PatientMountsList").then(
                                      (mod) => mod.PatientMountsList
                                    ),
                                },
                                {
                                  path: routesConfig.newMount.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Imaging/MountRoute/NewMountModalPage"
                                    ).then((mod) => mod.NewMountModalPage),
                                },
                                {
                                  path: routesConfig.imagingCompare.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Imaging/CompareImages/CompareImagesRoute"
                                    ).then((mod) => mod.CompareImagesRoute),
                                },
                                {
                                  path: routesConfig.imagingCompareSelectImages.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Imaging/CompareImages/SelectMountImagesModalRoute"
                                    ).then((mod) => mod.SelectMountImagesModalRoute),
                                },
                                {
                                  path: routesConfig.imagingEditImage.path,
                                  loadComponent: () =>
                                    import("components/PatientProfile/Imaging/EditImageRoute").then(
                                      (mod) => mod.EditImageRoute
                                    ),
                                },
                                {
                                  path: routesConfig.mountDetails.path,
                                  loadComponent: () =>
                                    import("components/PatientProfile/Imaging/MountRoute/MountRoute").then(
                                      (mod) => mod.MountRoute
                                    ),
                                },
                              ],
                            },
                            {
                              path: routesConfig.patientDocuments.path,
                              permission: {
                                domain: patientRoutePermissions.documents.domain,
                                action: patientRoutePermissions.documents.action,
                              },
                              loadComponent: () =>
                                import("components/PatientProfile/Documents/PatientDocuments").then(
                                  (mod) => mod.PatientDocuments
                                ),
                            },
                            {
                              path: routesConfig.patientForms.path,
                              permission: {
                                domain: patientRoutePermissions.forms.domain,
                                action: patientRoutePermissions.forms.action,
                              },
                              loadComponent: () =>
                                import("components/PatientProfile/Forms/PatientFormsRoute").then(
                                  (mod) => mod.PatientFormsRoute
                                ),
                            },
                            {
                              permission: {
                                domain: patientRoutePermissions.billing.domain,
                                action: patientRoutePermissions.billing.action,
                              },
                              loadComponent: () =>
                                import("components/PatientProfile/Billing/Billing").then(
                                  (mod) => mod.Billing
                                ),

                              children: [
                                {
                                  path: routesConfig.patientBilling.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Billing/Ledger/AppointmentsAndAdjustmentsPage"
                                    ).then((mod) => mod.AppointmentsAndAdjustmentsPage),
                                },
                                {
                                  path: routesConfig.importedLedger.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Billing/ImportedLedger/ImportedLedgerRoute"
                                    ).then((mod) => mod.ImportedLedgerRoute),
                                },
                                {
                                  path: routesConfig.patientInvoices.path,
                                  loadComponent: () =>
                                    import("components/PatientProfile/Billing/Ledger/InvoicesPage").then(
                                      (mod) => mod.InvoicesPage
                                    ),
                                },
                                {
                                  path: routesConfig.patientPayments.path,
                                  loadComponent: () =>
                                    import("components/PatientProfile/Billing/Ledger/PaymentsPage").then(
                                      (mod) => mod.PaymentsPage
                                    ),
                                },
                                {
                                  path: routesConfig.patientWalletActivity.path,
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Billing/Ledger/WalletActivityPage"
                                    ).then((mod) => mod.WalletActivityPage),
                                },
                                {
                                  path: routesConfig.createInvoice.path,
                                  permission: {
                                    domain: "BILLING",
                                    action: "COLLECT_MANAGE",
                                  },
                                  loadComponent: () =>
                                    import("components/PatientProfile/Billing/CreateInvoicePage").then(
                                      (mod) => mod.CreateInvoicePage
                                    ),
                                },
                                {
                                  path: routesConfig.viewInvoice.path,
                                  permission: {
                                    domain: "BILLING",
                                    action: "ACCESS_ALL",
                                  },
                                  loadComponent: () =>
                                    import("components/PatientProfile/Billing/ViewInvoiceRoute").then(
                                      (mod) => mod.ViewInvoiceRoute
                                    ),
                                },
                                {
                                  path: routesConfig.collectPayment.path,
                                  permission: {
                                    domain: "BILLING",
                                    action: "COLLECT_MANAGE",
                                  },
                                  loadComponent: () =>
                                    import(
                                      "components/PatientProfile/Billing/Payment/CollectPaymentRoute"
                                    ).then((mod) => mod.CollectPaymentRoute),
                                },
                                {
                                  path: routesConfig.viewPayment.path,
                                  loadComponent: () =>
                                    import("components/PatientProfile/Billing/ViewPaymentPage").then(
                                      (mod) => mod.ViewPaymentPage
                                    ),
                                },
                                {
                                  path: routesConfig.editPayment.path,
                                  permission: {
                                    domain: "BILLING",
                                    action: "EDIT_PAYMENT",
                                  },
                                  loadComponent: () =>
                                    import("components/PatientProfile/Billing/Payment/EditPaymentRoute").then(
                                      (mod) => mod.EditPaymentRoute
                                    ),
                                },
                              ],
                            },
                            {
                              path: routesConfig.patientCommunication.path,
                              permission: {
                                domain: patientRoutePermissions.communication.domain,
                                action: patientRoutePermissions.communication.action,
                              },
                              loadComponent: () =>
                                import(
                                  "components/PatientProfile/Communication/PatientCommunicationRoute"
                                ).then((mod) => mod.PatientCommunicationRoute),
                            },

                            {
                              path: routesConfig.patientErx.path,
                              permission: {
                                domain: patientRoutePermissions.erx.domain,
                                action: patientRoutePermissions.erx.action,
                              },
                              loadComponent: () =>
                                import("components/PatientProfile/Erx/PatientErxRoute").then(
                                  (mod) => mod.PatientErxRoute
                                ),
                            },
                          ],
                        },
                      ],
                    },
                    {
                      loadComponent: () =>
                        import("components/Employee/EmployeesApp").then((mod) => mod.EmployeesApp),
                      children: [
                        {
                          loadComponent: () =>
                            import("components/Employee/EmployeeRouteWrapper").then(
                              (mod) => mod.EmployeeRouteWrapper
                            ),

                          children: [
                            {
                              path: routesConfig.employee.path,
                              loadComponent: () =>
                                import("components/EmployeeProfile/EmployeeInfo").then(
                                  (mod) => mod.EmployeeInfo
                                ),
                            },
                            {
                              path: routesConfig.employeeRole.path,
                              loadComponent: () =>
                                import("components/EmployeeProfile/EmployeeRoleRoute").then(
                                  (mod) => mod.EmployeeRoleRoute
                                ),
                            },
                            {
                              path: routesConfig.employeeLicensing.path,
                              loadComponent: () =>
                                import("components/EmployeeProfile/EmployeeLicensingRoute").then(
                                  (mod) => mod.EmployeeLicensingRoute
                                ),
                            },
                            {
                              path: routesConfig.employeeScheduling.path,
                              loadComponent: () =>
                                import("components/EmployeeProfile/EmployeeSchedulingRoute").then(
                                  (mod) => mod.EmployeeSchedulingRoute
                                ),
                            },
                            {
                              path: routesConfig.employeeTimesheet.path,
                              loadComponent: () =>
                                import("components/EmployeeProfile/EmployeeTimesheetGuard").then(
                                  (mod) => mod.EmployeeTimesheetGuard
                                ),
                            },
                            {
                              path: routesConfig.employeeDocuments.path,
                              loadComponent: () =>
                                import("components/EmployeeProfile/EmployeeDocuments").then(
                                  (mod) => mod.EmployeeDocuments
                                ),
                            },
                            {
                              path: routesConfig.employeeReferral.path,
                              loadComponent: () =>
                                import("components/EmployeeProfile/EmployeeReferralRoute").then(
                                  (mod) => mod.EmployeeReferralRoute
                                ),
                            },
                          ],
                        },
                        {
                          permission: {
                            domain: "EMPLOYEE_SETTINGS",
                            action: "ACCESS_ALL",
                          },
                          loadComponent: () =>
                            import("components/Employee/EmployeesManagementAppWrapper").then(
                              (mod) => mod.EmployeesManagementAppWrapper
                            ),

                          children: [
                            {
                              path: routesConfig.employees.path,
                              permission: {
                                domain: "EMPLOYEE_SETTINGS",
                                action: "ACCESS_ALL",
                              },
                              loadComponent: () =>
                                import("components/Employee/EmployeesRoute").then(
                                  (mod) => mod.EmployeesRoute
                                ),
                            },
                            {
                              permission: {
                                domain: "EMPLOYEE_SETTINGS",
                                action: "EDIT_ROLE",
                              },
                              loadComponent: () =>
                                import("components/Roles/RolesApp").then((mod) => mod.RolesApp),
                              children: [
                                {
                                  path: routesConfig.roles.path,
                                  loadComponent: () =>
                                    import("components/Roles/RolesRoute").then((mod) => mod.RolesRoute),
                                },
                                {
                                  path: routesConfig.roleCreate.path,
                                  loadComponent: () =>
                                    import("components/Roles/CreateRoleRoute").then(
                                      (mod) => mod.CreateRoleRoute
                                    ),
                                },
                                {
                                  path: routesConfig.roleEdit.path,
                                  loadComponent: () =>
                                    import("components/Roles/EditRoleRoute").then((mod) => mod.EditRoleRoute),
                                },
                                {
                                  path: routesConfig.roleCopy.path,
                                  loadComponent: () =>
                                    import("components/Roles/CopyRoleRoute").then((mod) => mod.CopyRoleRoute),
                                },
                              ],
                            },
                            {
                              path: routesConfig.electronicPrescribing.path,
                              permission: {
                                domain: "EMPLOYEE_SETTINGS",
                                action: "ACCESS_ALL",
                              },
                              loadComponent: () =>
                                import("components/Erx/ElectronicPrescribingRoute").then(
                                  (mod) => mod.ElectronicPrescribingRoute
                                ),
                            },
                          ],
                        },
                      ],
                    },
                    {
                      permission: {
                        domain: "CLAIMS",
                        action: "ACCESS_ALL",
                      },
                      loadComponent: () => import("components/Claims/ClaimsApp").then((mod) => mod.ClaimsApp),
                      children: [
                        {
                          path: routesConfig.claims.path,
                          loadComponent: () =>
                            import("components/Claims/Claims/ClaimsRoute").then((mod) => mod.ClaimsRoute),
                        },
                        {
                          path: routesConfig.preAuths.path,
                          loadComponent: () =>
                            import("components/Claims/PreAuths/PreAuthsRoute").then(
                              (mod) => mod.PreAuthsRoute
                            ),
                        },
                        {
                          path: routesConfig.eobs.path,
                          loadComponent: () =>
                            import("components/Claims/Eobs/EobsRoute").then((mod) => mod.EobsRoute),
                        },
                        {
                          path: routesConfig.claim.path,
                          loadComponent: () =>
                            import("components/Claim/ClaimRoute").then((mod) => mod.ClaimRoute),
                        },
                        {
                          path: routesConfig.claimAdjustment.path,
                          loadComponent: () =>
                            import("components/Claim/AdjustmentRoute").then(
                              (mod) => mod.ClaimAdjustmentRoute
                            ),
                        },
                        {
                          path: routesConfig.claimAttachments.path,
                          loadComponent: () =>
                            import("components/Claim/Attachments/ClaimAttachmentsRoute").then(
                              (mod) => mod.ClaimAttachmentsRoute
                            ),
                        },
                        {
                          path: routesConfig.claimEobs.path,
                          loadComponent: () =>
                            import("components/Claim/Attachments/ClaimEobsRoute").then(
                              (mod) => mod.ClaimEobsRoute
                            ),
                        },
                        {
                          path: routesConfig.claimHistory.path,
                          loadComponent: () =>
                            import("components/Claim/HistoryRoute").then((mod) => mod.ClaimHistoryRoute),
                        },
                        {
                          path: routesConfig.eob.path,
                          loadComponent: () => import("components/Eob/EobRoute").then((mod) => mod.EobRoute),
                        },
                      ],
                    },
                    {
                      permission: {
                        domain: "PRACTICE_SETTINGS",
                        action: "ACCESS_ALL",
                      },
                      loadComponent: () =>
                        import("components/Settings/SettingsRoute").then((mod) => mod.SettingsRoute),
                      children: [
                        {
                          loadComponent: () =>
                            import("components/Settings/SettingsApp").then((mod) => mod.SettingsApp),
                          children: [
                            {
                              path: paths.settingsSection({ section: "sensor-testing" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PRACTICE",
                              },
                              loadComponent: () =>
                                import(
                                  "components/Settings/OnboardingSettings/OnboardingSensorTestingRoute"
                                ).then((mod) => mod.OnboardingSensorTestingRoute),
                            },
                            {
                              path: routesConfig.sensorTestingSchedule.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PRACTICE",
                              },
                              loadComponent: () =>
                                import(
                                  "components/Settings/OnboardingSettings/OnboardingScheduleSensorTestingRoute"
                                ).then((mod) => mod.OnboardingScheduleSensorTestingRoute),
                            },
                            {
                              path: paths.settingsSection({ section: "business-information" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PRACTICE",
                              },
                              loadComponent: () =>
                                import("components/Settings/BusinessInformationRoute").then(
                                  (mod) => mod.BusinessInformationRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "contact-information" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PRACTICE",
                              },
                              loadComponent: () =>
                                import("components/Settings/ContactInformationRoute").then(
                                  (mod) => mod.ContactInformationRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "working-hours" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PRACTICE",
                              },
                              loadComponent: () =>
                                import("components/Settings/PracticeWorkingHoursRoute").then(
                                  (mod) => mod.PracticeWorkingHoursRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "holiday-calendar" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PRACTICE",
                              },
                              loadComponent: () =>
                                import("components/Settings/PracticeHolidaysRoute").then(
                                  (mod) => mod.PracticeHolidaysRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "practice-rooms" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PRACTICE",
                              },
                              loadComponent: () =>
                                import("components/Settings/PracticeRoomsRoute").then(
                                  (mod) => mod.PracticeRoomsRoute
                                ),
                            },

                            {
                              path: paths.settingsSection({ section: "phones" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PRACTICE",
                              },
                              loadComponent: () =>
                                import("components/Settings/PracticePhonesRoute").then(
                                  (mod) => mod.PracticePhonesRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "patient-folders" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_FORMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/PatientFoldersRoute").then(
                                  (mod) => mod.PatientFoldersRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "procedure-codes" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_SCHEDULING",
                              },
                              isLivePracticeRequired: true,
                              loadComponent: () =>
                                import("components/Settings/ProcedureCodesRoute").then(
                                  (mod) => mod.ProcedureCodesRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "appointment-tags" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_SCHEDULING",
                              },
                              loadComponent: () =>
                                import("components/Settings/AppointmentTagsRoute").then(
                                  (mod) => mod.AppointmentTagsRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "timesheets" }),
                              // Access to this settings section is controlled by the `EMPLOYEE_SETTINGS` domain
                              permission: {
                                domain: "EMPLOYEE_SETTINGS",
                                action: "ACCESS_ALL",
                              },
                              loadComponent: () =>
                                import("components/Settings/EmployeeTimesheetsRoute").then(
                                  (mod) => mod.EmployeeTimesheetsRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "appointment-card-design" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_SCHEDULING",
                              },
                              loadComponent: () =>
                                import("components/Settings/ScheduleDesignRoute").then(
                                  (mod) => mod.ScheduleDesignRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "appointment-categories" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_SCHEDULING",
                              },
                              loadComponent: () =>
                                import("components/Settings/AppointmentCategoriesRoute").then(
                                  (mod) => mod.AppointmentCategoriesRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "route-slip" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_SCHEDULING",
                              },
                              loadComponent: () =>
                                import("components/Settings/RouteSlip/RouteSlipRoute").then(
                                  (mod) => mod.RouteSlipRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "online-booking" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_SCHEDULING",
                              },
                              loadComponent: () =>
                                import("components/Settings/OnlineBookingFeatureFlagRoute").then(
                                  (mod) => mod.OnlineBookingFeatureFlagRoute
                                ),
                            },
                            {
                              path: routesConfig.noteAlerts.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_FORMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/Notes/AlertsRoute").then(
                                  (mod) => mod.AlertsRoute
                                ),
                            },
                            {
                              path: routesConfig.noteTags.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_FORMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/Notes/TagsRoute").then((mod) => mod.TagsRoute),
                            },

                            {
                              path: routesConfig.feeSchedules.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_INSURANCE",
                              },
                              isLivePracticeRequired: true,
                              loadComponent: () =>
                                import("components/Settings/FeeSchedules/FeeSchedulesRoute").then(
                                  (mod) => mod.FeeSchedulesRoute
                                ),
                            },
                            {
                              path: routesConfig.feeScheduleDetails.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_INSURANCE",
                              },
                              loadComponent: () =>
                                import("components/Settings/FeeSchedules/FeeScheduleDetailsPage").then(
                                  (mod) => mod.FeeScheduleDetailsPage
                                ),
                            },

                            {
                              path: routesConfig.insuranceCarriers.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_INSURANCE",
                              },
                              isLivePracticeRequired: true,
                              loadComponent: () =>
                                import("components/Settings/InsuranceCarriers/InsuranceCarriersRoute").then(
                                  (mod) => mod.InsuranceCarriersRoute
                                ),
                            },
                            {
                              path: routesConfig.insuranceDetails.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_INSURANCE",
                              },
                              loadComponent: () =>
                                import("components/Settings/InsuranceCarriers/InsuranceDetails").then(
                                  (mod) => mod.InsuranceDetailsRoute
                                ),
                            },
                            {
                              path: routesConfig.insurancePlans.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_INSURANCE",
                              },
                              loadComponent: () =>
                                import("components/Settings/InsurancePlans/InsurancePlansRoute").then(
                                  (mod) => mod.InsurancePlansRoute
                                ),
                            },
                            {
                              path: routesConfig.insurancePreferences.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_INSURANCE",
                              },
                              loadComponent: () =>
                                import(
                                  "components/Settings/InsurancePreferences/InsurancePreferencesRoute"
                                ).then((mod) => mod.InsurancePreferencesRoute),
                            },
                            {
                              path: paths.settingsSection({ section: "claim-settings" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_CLAIMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/ClaimSettingsRoute").then(
                                  (mod) => mod.ClaimSettingsRoute
                                ),
                            },
                            {
                              path: routesConfig.settingsActivateDentalXChange.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_CLAIMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/ClaimSettingsActivateDentalXChangeRoute").then(
                                  (mod) => mod.ClaimSettingsActivateDentalXChangeRoute
                                ),
                            },
                            {
                              path: routesConfig.clinicalNoteForms.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_CHARTING",
                              },
                              loadComponent: () =>
                                import("components/Settings/ChartingSettings/ClinicalNoteFormsRoute").then(
                                  (mod) => mod.ClinicalNoteFormsRoute
                                ),
                            },
                            {
                              path: routesConfig.editClinicalNotesForms.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_CHARTING",
                              },
                              loadComponent: () =>
                                import("components/Settings/ChartingSettings/EditClinicalNoteFormRoute").then(
                                  (mod) => mod.EditClinicalNoteFormRoute
                                ),
                            },
                            {
                              path: routesConfig.clinicalNoteFormPreview.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_CHARTING",
                              },
                              loadComponent: () =>
                                import(
                                  "components/Settings/ChartingSettings/ClinicalNoteFormPreviewRoute"
                                ).then((mod) => mod.ClinicalNoteFormPreviewRoute),
                            },
                            {
                              path: paths.settingsSection({ section: "procedure-shortcuts" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_CHARTING",
                              },
                              loadComponent: () =>
                                import("components/Settings/ProcedureShortcutsRoute").then(
                                  (mod) => mod.ProcedureShortcutsRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "labs" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_LAB_CASES",
                              },
                              loadComponent: () =>
                                import("components/Settings/LabsSettingsRoute").then(
                                  (mod) => mod.LabsSettingsRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "lab-case-types" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_LAB_CASES",
                              },
                              loadComponent: () =>
                                import("components/Settings/LabCaseTypesSettingsRoute").then(
                                  (mod) => mod.LabCaseTypesSettingsRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "lab-return-reasons" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_LAB_CASES",
                              },
                              loadComponent: () =>
                                import("components/Settings/LabReturnReasonsSettingsRoute").then(
                                  (mod) => mod.LabReturnReasonsSettingsRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "pos-devices" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PAYMENTS",
                              },
                              loadComponent: () =>
                                import("components/Settings/ArchyPaymentTerminalRoute").then(
                                  (mod) => mod.ArchyPaymentTerminalRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "billing-merchant" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PAYMENTS",
                              },
                              loadComponent: () =>
                                import("components/Settings/BillingMerchantRoute").then(
                                  (mod) => mod.BillingMerchantRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "payment-processing" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PAYMENTS",
                              },
                              loadComponent: () =>
                                import("components/Settings/PaymentProcessingRoute").then(
                                  (mod) => mod.PaymentProcessingRoute
                                ),
                            },
                            {
                              path: routesConfig.setupFinix.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PAYMENTS",
                              },
                              loadComponent: () =>
                                import("components/Settings/BillingMerchantSetupFinixRoute").then(
                                  (mod) => mod.BillingMerchantSetupFinixRoute
                                ),
                            },
                            {
                              path: routesConfig.setupAdyen.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PAYMENTS",
                              },
                              loadComponent: () =>
                                import("components/Settings/PaymentsAdyenOnboardingRoute").then(
                                  (mod) => mod.PaymentsAdyenOnboardingRoute
                                ),
                            },
                            {
                              path: routesConfig.adyenComplete.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PAYMENTS",
                              },
                              loadComponent: () =>
                                import("components/Settings/PaymentsAdyenSetupCompleteRoute").then(
                                  (mod) => mod.PaymentsAdyenSetupCompleteRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "payment-patient-portal" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PAYMENTS",
                              },
                              loadComponent: () =>
                                import("components/Settings/BillingPatientPortalRoute").then(
                                  (mod) => mod.BillingPatientPortalRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "custom-adjustments" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_PAYMENTS",
                              },
                              loadComponent: () =>
                                import("components/Settings/BillingCustomAdjustmentsRoute").then(
                                  (mod) => mod.BillingCustomAdjustmentsRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "email-authentication" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_COMMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/EmailAuthenticationRoute").then(
                                  (mod) => mod.EmailAuthenticationRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "email-preferences" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_COMMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/EmailPreferencesRoute").then(
                                  (mod) => mod.EmailPreferencesRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "messaging-services" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_COMMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/MessagingServicesRoute").then(
                                  (mod) => mod.MessagingServicesRoute
                                ),
                            },
                            {
                              path: routesConfig.setupTwilioPractice.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_COMMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/MessagingServicesSetupTwilioPracticeRoute").then(
                                  (mod) => mod.MessagingServicesSetupTwilioPracticeRoute
                                ),
                            },
                            {
                              path: routesConfig.setupTwilioPhone.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_COMMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/MessagingServicesSetupTwilioPhoneRoute").then(
                                  (mod) => mod.MessagingServicesSetupTwilioPhoneRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "imaging" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_CHARTING",
                              },
                              loadComponent: () =>
                                import(
                                  "components/Settings/ImagingSettings/CalibrateImagingDevice/V1/ImagingSettingsRoute"
                                ).then((mod) => mod.ImagingSettingsRoute),
                            },
                            {
                              path: paths.settingsSection({ section: "imaging-v2" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_CHARTING",
                              },
                              loadComponent: () =>
                                import("components/Settings/ImagingSettings/ImagingSettingsRouteV2").then(
                                  (mod) => mod.ImagingSettingsRouteV2
                                ),
                            },
                            {
                              path: routesConfig.settingsImagingSensor.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_CHARTING",
                              },
                              loadComponent: () =>
                                import(
                                  "components/Settings/ImagingSettings/CalibrateImagingDevice/V1/SensorSettingsRoute"
                                ).then((mod) => mod.SensorSettingsRoute),
                            },
                            {
                              path: routesConfig.settingsImagingSensorV2.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_CHARTING",
                              },
                              loadComponent: () =>
                                import(
                                  "components/Settings/ImagingSettings/CalibrateImagingDevice/ConfigureSensorSettingsProfileRoute"
                                ).then((mod) => mod.ConfigureSensorSettingsProfileRoute),
                            },
                            {
                              path: routesConfig.forms.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_FORMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/Forms/FormsRoute").then((mod) => mod.FormsRoute),
                            },
                            {
                              path: paths.settingsSection({ section: "referral-options" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_FORMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/Forms/ReferralOptions/ReferralOptionsRoute").then(
                                  (mod) => mod.ReferralOptionsRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "setup-kiosk" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_FORMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/Forms/FormsKiosk/FormsKioskRoute").then(
                                  (mod) => mod.FormsKioskRoute
                                ),
                            },
                            {
                              path: routesConfig.editForm.path,
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_FORMS",
                              },
                              loadComponent: () =>
                                import("components/Settings/Forms/EditFormRoute").then(
                                  (mod) => mod.EditFormRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "ip-auth" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_SECURITY",
                              },
                              loadComponent: () =>
                                import(
                                  "components/Settings/Security/IPAuthorization/IPAuthSettingsRoute"
                                ).then((mod) => mod.IPAuthSettingsRoute),
                            },
                            {
                              path: paths.settingsSection({ section: "mfa" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_SECURITY",
                              },
                              loadComponent: () =>
                                import("components/Settings/Security/MFASettings/MFASettingsRoute").then(
                                  (mod) => mod.MFASettingsRoute
                                ),
                            },
                            {
                              path: paths.settingsSection({ section: "server-access" }),
                              permission: {
                                domain: "PRACTICE_SETTINGS",
                                action: "ACCESS_SECURITY",
                              },
                              loadComponent: () =>
                                import(
                                  "components/Settings/OnboardingSettings/ServerAccess/ServerAccessRoute"
                                ).then((mod) => mod.ServerAccessRoute),
                            },
                          ],
                        },
                      ],
                    },
                    {
                      permission: {
                        domain: "APPOINTMENT_MANAGEMENT",
                        action: "ACCESS_ALL",
                      },
                      loadComponent: () =>
                        import("components/ScheduleAppointments/SchedulingApp").then(
                          (mod) => mod.SchedulingApp
                        ),

                      children: [
                        {
                          path: routesConfig.schedule.path,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/ScheduleRoute").then(
                              (mod) => mod.ScheduleRoute
                            ),
                        },
                        {
                          path: routesConfig.addPatientAppointment.path,
                          isLivePracticeRequired: true,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/AddPatientAppointmentRoute").then(
                              (mod) => mod.AddPatientAppointmentRoute
                            ),
                        },
                        {
                          path: routesConfig.scheduleAppointment.path,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/ScheduleAppointmentRoute").then(
                              (mod) => mod.ScheduleAppointmentRoute
                            ),
                        },
                        {
                          path: routesConfig.scheduleAsapList.path,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/ScheduleAsapListRoute").then(
                              (mod) => mod.ScheduleAsapListRoute
                            ),
                        },
                        {
                          path: routesConfig.scheduleRequestsList.path,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/ScheduleRequestsList").then(
                              (mod) => mod.ScheduleRequestsList
                            ),
                        },
                        {
                          path: routesConfig.scheduleAsap.path,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/ScheduleAppointmentRoute").then(
                              (mod) => mod.ScheduleAppointmentRoute
                            ),
                        },
                        {
                          path: routesConfig.scheduleBlock.path,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/ScheduleBlockRoute").then(
                              (mod) => mod.ScheduleBlockRoute
                            ),
                        },
                        {
                          path: routesConfig.scheduleGuide.path,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/ScheduleGuideRoute").then(
                              (mod) => mod.ScheduleGuideRoute
                            ),
                        },
                        {
                          path: routesConfig.scheduleProviderGuide.path,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/ScheduleProviderGuideRoute").then(
                              (mod) => mod.ScheduleProviderGuideRoute
                            ),
                        },
                        {
                          path: routesConfig.editAppointment.path,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/EditAppointmentRoute").then(
                              (mod) => mod.EditAppointmentRoute
                            ),
                        },
                        {
                          path: routesConfig.editBlock.path,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/EditBlockRoute").then(
                              (mod) => mod.EditBlockRoute
                            ),
                        },
                        {
                          path: routesConfig.editGuide.path,
                          loadComponent: () =>
                            import("components/ScheduleAppointments/EditGuideRoute").then(
                              (mod) => mod.EditGuideRoute
                            ),
                        },
                        {
                          path: routesConfig.huddle.path,
                          loadComponent: () =>
                            import("components/DailyHuddle/DailyHuddleRoute").then(
                              (mod) => mod.DailyHuddleRoute
                            ),
                        },
                      ],
                    },
                    {
                      path: routesConfig.messaging.path,
                      permission: {
                        domain: "COMMUNICATION",
                        action: "ACCESS_ALL",
                      },
                      loadComponent: () =>
                        import("components/Messaging/MessagingRoute").then((mod) => mod.MessagingRoute),
                    },
                    {
                      permission: {
                        domain: "COMMUNICATION",
                        action: "BULK_MESSAGE",
                      },
                      loadComponent: () =>
                        import("components/Communications/CommunicationsApp").then(
                          (mod) => mod.CommunicationsApp
                        ),

                      children: [
                        {
                          path: routesConfig.communications.path,
                          loadComponent: () =>
                            import("components/Communications/CommunicationsRoute").then(
                              (mod) => mod.CommunicationsRoute
                            ),
                        },
                        {
                          path: routesConfig.journey.path,
                          loadComponent: () =>
                            import("components/Communications/Automations/AutomationJourneyRoute").then(
                              (mod) => mod.AutomationJourneyRoute
                            ),
                        },
                        {
                          path: routesConfig.createAutomationAction.path,
                          loadComponent: () =>
                            import("components/Communications/Automations/CreateAutomationActionRoute").then(
                              (mod) => mod.CreateAutomationActionRoute
                            ),
                        },
                        {
                          path: routesConfig.editAutomationAction.path,
                          loadComponent: () =>
                            import("components/Communications/Automations/EditAutomationActionRoute").then(
                              (mod) => mod.EditAutomationActionRoute
                            ),
                        },
                        {
                          path: routesConfig.campaign.path,
                          loadComponent: () =>
                            import("components/Communications/CampaignRoute").then(
                              (mod) => mod.CampaignRoute
                            ),
                        },
                        {
                          path: routesConfig.campaignGroup.path,
                          loadComponent: () =>
                            import("components/Communications/CampaignGroupRoute").then(
                              (mod) => mod.CampaignGroupRoute
                            ),
                        },
                        {
                          path: routesConfig.campaignEmail.path,
                          loadComponent: () =>
                            import("components/Communications/EmailEditorRoute").then(
                              (mod) => mod.EmailEditorRoute
                            ),
                        },
                        {
                          path: routesConfig.campaignTemplates.path,
                          loadComponent: () =>
                            import("components/Communications/EmailTemplatesRoute").then(
                              (mod) => mod.EmailTemplatesRoute
                            ),
                        },
                      ],
                    },
                    {
                      path: routesConfig.erx.path,
                      loadComponent: () =>
                        import("components/Erx/ErxNotificationsRoute").then(
                          (mod) => mod.ErxNotificationsRoute
                        ),
                    },
                    {
                      path: routesConfig.ssoZendesk.path,
                      loadComponent: () =>
                        import("components/SSO/SSOZendeskRoute").then((mod) => mod.SSOZendeskRoute),
                    },
                    {
                      path: routesConfig.ssoLearnupon.path,
                      loadComponent: () =>
                        import("components/SSO/SSOLearnuponRoute").then((mod) => mod.SSOLearnuponRoute),
                    },
                    {
                      path: routesConfig.labCases.path,
                      permission: {
                        domain: "LAB_CASE",
                        action: "ACCESS_ALL",
                      },
                      loadComponent: () =>
                        import("components/LabCases/LabCasesRoute").then((mod) => mod.LabCasesRoute),
                    },
                    isOneOf(context.env.REACT_APP_ENVIRONMENT, ["development", "preprod", "preview"])
                      ? {
                          path: "verify-odontogram",
                          loadComponent: () =>
                            import("components/Charting/internal/VerifyOdontogramRoute").then(
                              (mod) => mod.VerifyOdontogramRoute
                            ),
                        }
                      : undefined,

                    {
                      path: "*",
                      loadComponent: () => import("components/NotFoundRoute").then((mod) => mod.NotFound),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ]);
};
